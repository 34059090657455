<template>
	<a-layout-header class="header">
		<div class="logo">汤为丽管理后台</div>
		<div style="flex: 1" />
		<a-menu v-if="isLogin" theme="dark" mode="horizontal" :style="{ lineHeight: '64px' }" @click="onMenuClick"
		 :selectedKeys="selectedKeys">
			<a-sub-menu key="user">
				<span slot="title">
					<a-icon type="user" /><span>{{ memberName }}</span></span>
				<a-menu-item key="logout">退出</a-menu-item>
			</a-sub-menu>
		</a-menu>
		<a-menu v-else theme="dark" mode="horizontal" :style="{ lineHeight: '64px' }" @click="onMenuClick" :selectedKeys="selectedKeys">
			<a-menu-item key="login">登录</a-menu-item>
		</a-menu>
	</a-layout-header>
</template>

<script>
	export default {
		data() {
			return {};
		},
		computed: {
			isLogin() {
				return !!this.$store.state.token
			},
			routeName() {
				return this.$route.name;
			},
			selectedKeys() {
				if (["login"].includes(this.routeName)) {
					return [this.routeName];
				} else {
					return [];
				}
			},
			memberName() {
				return this.$store.state.minfo.userName || ''
			}
		},
		methods: {
			onMenuClick(menu) {
				if (this.$route.name == menu.key) {
					return;
				}
				if (menu.key == "logout") {
					this.$confirm({
						title: "提示",
						content: "确定要退出吗？",
						onOk: async () => {
							await this.$api.get('/login_out')
							this.$storage.set('token', false)
							location.replace('/')
						},
					});
					return;
				}
				if (["login"].includes(menu.key)) {
					this.$router.replace({
						name: menu.key
					});
				}
			},
		},
	};
</script>

<style lang="less">
	.header {
		display: flex;
		flex-direction: row;

		.logo {
			height: 64px;
			margin-right: 28px;
			float: left;
			color: #fff;
			font-size: 18px;
			font-weight: bold;
		}
	}
</style>

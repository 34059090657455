<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<a-spin :spinning="$store.state.loading">
				<WsHeader />
				<router-view />
			</a-spin>
		</div>
	</a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import WsHeader from './components/WsHeader';
export default {
	components: {
		WsHeader
	},
	data() {
		return {
			locale: zhCN
		};
	}
};
</script>
<style lang="less">
html,
body {
	height: 100%;
	background-color: #ffffff;
}

#app {
	min-height: 100%;
	font-family: -apple-system, Avenir, Helvetica, Arial, sans-serif, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.contents {
	flex: 1;
}

.ant-space-vertical {
	width: 100%;
}

.image-preview {
	.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
		margin-left: 0;
	}
	.ant-modal-confirm-body {
		.anticon {
			display: none;
		}
	}
}

img {
	max-width: 100%;
}

.statistics {
	margin-bottom: 10px;
	padding: 0 4px;
	color: #1890ff;
	span + span {
		margin-left: 40px;
	}
}
</style>

import axios from 'axios'
import dayjs from 'dayjs'

import {
	message,
	Modal
} from 'ant-design-vue';
import {
	baseURL
} from '@/config'
import storage from '@/utils/storage'
import store from '@/store'
const api = axios.create({
	baseURL,
	timeout: 60000
})

api.interceptors.request.use(async config => {
	const token = storage.get('token')
	if (token) {
		config.headers['token'] = token
	}
	// config.headers['Content-Type'] = "application/x-www-form-urlencoded"
	console.info('Api.config:', config)
	return config
})

function arrayBufferToString(buffer, encoding = 'utf-8') {
	const decoder = new TextDecoder(encoding);
	let str = decoder.decode(buffer);
	try {
		str = JSON.parse(str)
	} catch (e) {}
	return str
}


api.interceptors.response.use((response = {}) => {
	console.info('Api.success:', response)
	let responseData = response && response.data || {}
	if (/excel/.test(response.headers['content-type'])) {
		var fname = '订单'
		if (/user_export/.test(response.config.url)) {
			fname = '用户'
		} else if (/withdrawal_export/.test(response.config.url)) {
			fname = '提现申请'
		} else if (/agent_export/.test(response.config.url)) {
			fname = '代理申请'
		} else if (/upgrade_export/.test(response.config.url)) {
			fname = '达标用户'
		} else if (/service_charge_export/.test(response.config.url)) {
			fname = '市场服务费'
		} else if (/finance_list_export/.test(response.config.url)) {
			fname = '财务订单'
		} else if (/credit_card_export/.test(response.config.url)) {
			fname = '智能用卡订单'
		} else if (/credit_real_name_export/.test(response.config.url)) {
			fname = '智能用卡实名列表'
		} else if (/finance_daily_export/.test(response.config.url)) {
			const params = response.config.params || {}
			fname = '财务'
			if (params.type == 1) {
				fname += '_可提现余额'
			} else if (params.type == 2) {
				fname += '_云库存'
			}
		}
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([responseData], {
			type: response.headers['content-type']
		}));
		link.download = `${fname}_${dayjs().format('YYYY-MM-DD')}.xls`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		message.success('导出成功');
		store.dispatch('setLoading', false)
		return responseData
	}
	if (responseData instanceof ArrayBuffer) {
		store.dispatch('setLoading', false)
		responseData = arrayBufferToString(responseData)
	}

	if (responseData.code == 200) {
		return responseData
	} else if (responseData.code == 401 || responseData.code == 1201) {
		storage.clear()
		store.dispatch('setToken', '')
		location.replace('/index.html#/login')
		return Promise.reject(false)
	} else if (responseData.code == 911 && /user_query_product/.test(response.config.url)) {
		// message.error(responseData.msg)
		return Promise.resolve(false)
	} else {
		message.error(responseData.msg)
		return Promise.reject(false)
	}
}, error => {
	console.info('Api.error:', error)
	Modal.warning({
		content: '系统异常，请联系管理员'
	})
	return false
})

export default api
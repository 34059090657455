import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
Vue.use(Vuex)

const TYPES = {
	'TOKEN': 'TOKEN',
	'USER_INFO': 'USER_INFO',
	'MEMBER_INFO': 'MEMBER_INFO',
	'MENUS': 'MENUS',
	'LOADING': 'LOADING'
}

var timer = null
export default new Vuex.Store({
	state: {
		token: '',
		minfo: {},
		menus: [],
		loading: false
	},
	mutations: {
		[TYPES.TOKEN](state, token) {
			state.token = token
		},
		[TYPES.MEMBER_INFO](state, minfo) {
			state.minfo = minfo
		},
		[TYPES.MENUS](state, menus) {
			state.menus = menus || []
		},
		[TYPES.LOADING](state, loading) {
			state.loading = loading
		},
	},
	actions: {
		async setToken(context, token = false) {
			context.commit(TYPES.TOKEN, token)
		},
		async setMemberInfo(context, minfo = {}) {
			context.commit(TYPES.MEMBER_INFO, minfo)
			if (context.state.token) {
				const res = await api.get('/admin_menu_list')
				if (res.code == 200) {
					context.commit(TYPES.MENUS, res.data || [])
				}
			}
		},
		async setMemberMenus(context, menus = []) {
			context.commit(TYPES.MENUS, menus)
		},
		async setLoading(context, loading = false) {
			timer = clearTimeout(timer)
			const timeout = typeof loading == 'number' ? loading : 10000
			if (loading) {
				timer = setTimeout(() => {
					context.dispatch('setLoading', false)
				}, timeout)
			}
			context.commit(TYPES.LOADING, loading)
		},
	}
})
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import storage from './utils/storage'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueClipboard from 'vue-clipboard2'
Vue.use(Antd);
Vue.use(VueClipboard)

Vue.prototype.$storage = storage
Vue.prototype.$api = api
Vue.config.productionTip = false
// const token = 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiU0hBMjU2In0=.eyJpc3MiOiJ6Z2hsIGp3dCIsImlhdCI6MTYwMDkxOTg5MSwiZXhwIjoxNjAwOTI3MDkxLCJhdWQiOiJ3d3cuemhpZ3VvaHVsaWFuLmNvbSIsInN1YiI6ImFwcCB1c2VyIiwicGFyYW1zIjp7ImFkbWluX3Bob25lIjoiMTMwODg4ODg4ODgiLCJ1aWQiOiI1M2I3MzUxMzBlNTlkNzMxZmVlNTE5ZTVlYTAwZWNmOSJ9fQ==.7205b80b0076ac3a9325d06686481946603d1ea730621ec02d85716ba0ca9f5e'
const token = storage.get('token') || false
const minfo = storage.get('minfo') || false
storage.set('token', token)
store.dispatch('setToken', token)
store.dispatch('setMemberInfo', minfo)

// 注册自定义拖拽指令，弥补 modal 组件不能拖动的缺陷
Vue.directive('drag-modal', (el, bindings, vnode) => {
  Vue.nextTick(() => {
    const { visible, destroyOnClose } = vnode.componentInstance
    // 防止未定义 destroyOnClose 关闭弹窗时dom未被销毁，指令被重复调用
    if (!visible) return
    const modal = el.getElementsByClassName('ant-modal')[0]
    const header = el.getElementsByClassName('ant-modal-header')[0]
    let left = 0
    let top = 0

    // 设置遮罩层可滚动
    setTimeout(() => {
      document.body.style.width = '100%'
      document.body.style.overflowY = 'inherit'
    }, 0)

    // 鼠标变成可移动的指示
    header.style.cursor = 'move'

    // 未定义 destroyOnClose 时，dom未被销毁，关闭弹窗再次打开，弹窗会停留在上一次拖动的位置
    if (!destroyOnClose) {
      left = modal.left || 0
      top = modal.top || 0
    }
    // top 初始值为 offsetTop
    top = top || modal.offsetTop
    header.onmousedown = e => {
      const startX = e.clientX
      const startY = e.clientY
      header.left = header.offsetLeft
      header.top = header.offsetTop
      el.onmousemove = event => {
        const endX = event.clientX
        const endY = event.clientY
        modal.left = header.left + (endX - startX) + left
        modal.top = header.top + (endY - startY) + top
        modal.style.left = modal.left + 'px'
        modal.style.top = modal.top + 'px'
      }
      el.onmouseup = event => {
        left = modal.left
        top = modal.top
        el.onmousemove = null
        el.onmouseup = null
        header.releaseCapture && header.releaseCapture()
      }
      header.setCapture && header.setCapture()
    }
  })
})


Vue.mixin({
	data() {
		return {
			docWidth: document.body.clientWidth
		}
	},
	methods: {
		async onCopySuccess() {
			this.$message.success('复制成功')
		},
		async onPreview(src) {
			this.$info({
				width: 600,
				title: null,
				centered: true,
				class: 'image-preview',
				content: this.$createElement('div', { style: 'max-height:80vh;overflow-y:auto' },
					[this.$createElement('img', {
						attrs: { src }
					})])
			});
		}
	}
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from '@/utils/storage.js'

Vue.use(VueRouter)

const routes = [{
		path: '/home',
		name: 'login',
		component: () => import('../views/layouts/BlankLayout.vue'),
		redirect: '/login',
		children: [{
			path: '/login',
			name: 'login',
			component: () => import('../views/users/login.vue')
		}]
	},
	{
		path: '/',
		name: 'dashboard',
		component: () => import('../views/layouts/UserLayout.vue'),
		redirect: '/dashboard',
		children: [{
				path: '/dashboard',
				name: 'index',
				title: '欢迎',
				component: () => import('../views/index.vue')
			}, {
				path: '/banners',
				name: 'banners',
				title: 'Banner管理',
				component: () => import('../views/banners/index.vue')
			}, {
				path: '/banners/create',
				name: 'bannerCreate',
				meta: {
					title: '新增Banner'
				},
				component: () => import('../views/banners/info.vue')
			}, {
				path: '/banners/:id/edit',
				name: 'bannerUpdate',
				meta: {
					title: '新增Banner'
				},
				component: () => import('../views/banners/info.vue')
			}, {
				path: '/banners/:id',
				name: 'bannerView',
				meta: {
					title: 'Banner详情'
				},
				component: () => import('../views/banners/info.vue')
			},
			{
				path: '/users',
				name: 'users',
				title: '用户管理',
				component: () => import('../views/users/index.vue')
			},

			{
				path: '/bonus',
				name: 'bonus',
				title: '分红管理',
				component: () => import('../views/bonus/index.vue')
			},
			{
				path: '/users/:id/edit',
				name: 'usersUpdate',
				meta: {
					title: '编辑用户',
				},
				component: () => import('../views/users/info.vue')
			},
			{
				path: '/users/:id',
				name: 'usersView',
				meta: {
					title: '用户详情',
				},
				component: () => import('../views/users/info.vue')
			},
			{
				path: '/orders',
				name: 'orders',
				title: '订单管理',
				component: () => import('../views/orders/index.vue')
			},
			{
				path: '/creditcard',
				name: 'creditcard',
				title: '智能用卡',
				component: () => import('../views/creditcard/index.vue')
			},
			{
				path: '/finances',
				name: 'finances',
				title: '财务管理',
				component: () => import('../views/finances/index.vue')
			},
			{
				path: '/goods',
				name: 'goods',
				title: '商品管理',
				component: () => import('../views/goods/index.vue')
			},
			{
				path: '/goods/create',
				name: 'goodsCreate',
				meta: {
					title: '新增商品',
				},
				component: () => import('../views/goods/info.vue')
			},
			{
				path: '/goods/:id/edit',
				name: 'goodsUpdate',
				meta: {
					title: '编辑商品',
				},
				component: () => import('../views/goods/info.vue')
			},
			{
				path: '/goods/:id',
				name: 'goodsView',
				meta: {
					title: '商品详情'
				},
				component: () => import('../views/goods/info.vue')
			},
			{
				path: '/agents',
				name: 'agents',
				title: '代理商管理',
				component: () => import('../views/agents/index.vue'),

			},
			{
				path: '/county',
				name: 'county',
				title: '区县代理商',
				component: () => import('../views/county/index.vue'),
				children: [{
					path: '/county/agents',
					name: 'countyagents',
					title: '区县代理商',
					component: () => import('../views/county/agents.vue')
				}]
			},
			{
				path: '/withdraw',
				name: 'withdraw',
				title: '用户提现管理',
				component: () => import('../views/withdraw/index.vue')
			},
			{
				path: '/upgradeuser',
				name: 'upgradeuser',
				title: '达标用户管理',
				component: () => import('../views/upgradeuser/index.vue')
			},
			{
				path: '/servicecharge',
				name: 'servicecharge',
				title: '市场服务费',
				component: () => import('../views/servicecharge/index.vue')
			},
			{
				path: '/system',
				name: 'system',
				title: '系统配置',
				component: () => import('../views/system/index.vue')
			},
			{
				path: '/config/team',
				name: 'team-config',
				title: '战队配置',
				component: () => import('../views/system/team.vue')
			},
			{
				path: '/articles',
				name: 'articles',
				title: '文章管理',
				component: () => import('../views/articles/index.vue')
			},
			{
				path: '/articles/create',
				name: 'articles-create',
				title: '新增文章',
				component: () => import('../views/articles/info.vue')
			},
			{
				path: '/articles/:id/edit',
				name: 'articles-edit',
				title: '编辑文章',
				component: () => import('../views/articles/info.vue')
			},
			{
				path: '/invites',
				name: 'invites',
				title: '邀请海报',
				component: () => import('../views/invites/index.vue')
			},
			{
				path: '/invites/create',
				name: 'invites-create',
				title: '新增海报',
				meta: { title: '新增海报' },
				component: () => import('../views/invites/info.vue')
			},
			{
				path: '/invites/:id/edit',
				name: 'invites-edit',
				title: '编辑海报',
				meta: { title: '编辑海报' },
				component: () => import('../views/invites/info.vue')
			},
			{
				path: '/invites/:id/info',
				name: 'invites-info',
				title: '查看海报',
				meta: { title: '查看海报' },
				component: () => import('../views/invites/info.vue')
			},
			{
				path: '/members',
				name: 'members',
				title: '成员列表',
				meta: { title: '成员列表' },
				component: () => import('../views/members/index.vue')
			},
		]
	},
	{
		path: '/articles/:id/preview',
		name: 'articles-preview',
		title: '文章预览',
		component: () => import('../views/articles/preview.vue')
	}
]

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes
})
router.beforeEach((to, from, next) => {
	console.info('router', to, from)
	if (!storage.get('token') && to.matched.some(v => v.name == 'dashboard')) {
		next('/login')
	} else {
		const minfo = storage.get('minfo') || {}
		if (minfo.isAdmin) {
			next()
		} else if (['orders', 'login'].includes(to.name)) {
			next()
		} else {
			next('/orders')
		}
	}
	// document.documentElement.scrollTo({
	// 	top: 0,
	// 	behavior: 'smooth'
	// })
})

export default router